<template>
	<div>
		<a-row class="ui-relative">
			<a-col :span="12" style="padding-right: 40px;">
				<div v-permission="['pc_jobs_common']">
					<topHeader text="常用功能"></topHeader>
					<div class="flex">
						<common :list="commonList"></common>
					</div>
				</div>
				<div v-permission="['pc_jobs_todo']" class="ui-margin__top40">
					<topHeader text="我的待办" hasMore @moreClick="onTodoList"></topHeader>
					<todoList></todoList>
				</div>
			</a-col>
			
			<div class="ui-line">
				<a-divider type="vertical" style="width: 1px;height: 100%;background: #F0F0F0;" />
			</div>
			<a-col :span="12" style="padding-left: 30px;">
				<div style="display: flex;">
					<div style="min-width: 50%;flex: 1;padding-right: 20px;" v-permission="['pc_jobs_notice']">
						<div>
							<topHeader text="通知公告" hasMore @moreClick="onNoticeClick"></topHeader>
							<noticeList></noticeList>
						</div>
					</div>
					<div style="min-width: 50%;flex: 1;padding-left: 20px;" v-permission="['pc_jobs_assets']">
						<div>
							<topHeader text="我的资产"></topHeader>
							<div>
								<div class="ui-property">
									<div class="ui-property__count" :style="'background-image: url('+ propertyCountImg +');'" @click="onMyProperty">
										<div class="ui-property__text">资产总数(件)</div>
										<div class="ui-property__allNum">
											<span>{{assetsData.assetsNum}}</span>
										</div>
									</div>
									<div class="ui-property__count" :style="'background-image: url('+ propertyMomeyImg +');'" @click="onMyProperty">
										<div class="ui-property__text">资产总价值(元)</div>
										<div class="ui-property__allNum">
											<span>{{assetsData.amount}}</span>
										</div>
									</div>
								</div>
								<div class="ui-property">
									<div class="ui-property__item" @click="onGotoPage('/property/lend')">
										<div class="ui-property__name">借出资产(件)</div>
										<div class="ui-property__num">{{assetsData.lendNum}}</div>
									</div>
									<div class="ui-property__item" @click="onGotoPage('/property/borrow')">
										<div class="ui-property__name">借入资产(件)</div>
										<div class="ui-property__num">{{assetsData.borrowNum}}</div>
									</div>
								</div>
								<div class="ui-property">
									<div class="ui-property__item" @click="onGotoPage('/property/transOut')">
										<div class="ui-property__name">调出资产(件)</div>
										<div class="ui-property__num">{{assetsData.exportNum}}</div>
									</div>
									<div class="ui-property__item" @click="onGotoPage('/property/transIn')">
										<div class="ui-property__name">调入资产(件)</div>
										<div class="ui-property__num">{{assetsData.importNum}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<a-row>
					<a-col :span="24">
						<div class="ui-margin__top40" v-permission="['pc_jobs_inventory']">
							<topHeader text="我的盘点"></topHeader>
							<inventory :inventoryData="inventoryHead" :unitRank="unitRank"></inventory>
						</div>
					</a-col>
				</a-row>
				<a-row>
					<a-col :span="24">
						<div class="ui-margin__top40" v-permission="['pc_jobs_transfer']">
							<topHeader text="盘点调度" hasMore @moreClick="onInventoryTrans"></topHeader>
							<inventoryTrans :inventoryData="inventoryList" :currTaskInfo="currTaskInfo"></inventoryTrans>
							<!-- <inventoryTrans></inventoryTrans> -->
						</div>
					</a-col>
				</a-row>
			</a-col>
		</a-row>
	</div>
</template>

<script>
	import { getFiscalAnalysis, getCurrentTask } from '@/service/modules/statistics.js';
	import { getMyAssets, getMyInventory } from '@/service/modules/jobs.js';
	import { getUnitTaskSearchList } from '@/service/modules/inventory.js';
	import topHeader from './components/topHeader.vue';
	import common from './components/commonItem.vue';
	import todoList from './components/todoList.vue';
	import noticeList from './components/noticeList.vue';
	import inventory from './components/inventory.vue';
	import inventoryTrans from './components/inventoryTrans.vue';
	import propertyCountImg from '@/assets/image/jobs/property-count.png';
	import propertyMomeyImg from '@/assets/image/jobs/property-momey.png';
	export default {
		components: { topHeader, common, todoList, noticeList, inventory, inventoryTrans },
		data() {
			return {
				assetsData: {},
				inventoryData: {},
				inventoryHead: {},
				inventoryList: [],
				unitRank: [],
				currTaskId: null,
				currTaskInfo: {},
				propertyCountImg,
				propertyMomeyImg,
				commonList: [{
					icon: require('@/assets/image/jobs/print.png'),
					text: '打印标签',
					link: "/print/index",
					permission: "pc_print"
				}, {
					icon: require('@/assets/image/jobs/property.png'),
					text: '我的资产',
					link: "/property/myself",
					permission: "pc_jobs_assets"
				}, {
					icon: require('@/assets/image/jobs/borrow.png'),
					text: '借入借出',
					// link: "/propertyManage/borrowAndLend",
					link: "/property/borrowAndLendManage",
					permission: "pc_assets_inout"
				}, {
					icon: require('@/assets/image/jobs/transfer.png'),
					text: '资产调拨',
					// link: "/propertyManage/allocate",
					link: "/property/allocateManage",
					permission: "pc_assets_transferManage"
				}, {
					icon: require('@/assets/image/jobs/download.png'),
					text: '下账管理',
					link: "/property/accounts",
					permission: "pc_assets_registration"
				}, {
					icon: require('@/assets/image/jobs/scrap.png'),
					text: '报废管理',
					link: "/property/scrap",
					permission: "pc_assets_scrap"
				},{
					icon: require('@/assets/image/jobs/scrap.png'),
					text: '报废管理',
					link: "/property/scrapManage",
					permission: "pc_assets_scrapManage"
				}, {
					icon: require('@/assets/image/jobs/inventory.png'),
					text: '盘点任务',
					link: "/inventory/task",
					permision: "pc_jobs_todo_task"
				}, {
					icon: require('@/assets/image/jobs/inventory.png'),
					text: '盘点任务',
					link: "/inventory/taskManage",
					permision: "pc_jobs_todo_taskManage"
				}, {
					icon: require('@/assets/image/jobs/inventory-loss.png'),
					text: '盘亏管理',
					link: "/inventory/loss",
					permission: "pc_inventory_loss"
				}, {
					icon: require('@/assets/image/jobs/inventory-loss.png'),
					text: '盘亏管理',
					link: "/inventory/lossManage",
					permission: "pc_inventory_lossManage"
				}, {
					icon: require('@/assets/image/jobs/inventory-profit.png'),
					text: '盘盈管理',
					link: "/inventory/profit",
					permission: "pc_inventory_profit"
				}, {
					icon: require('@/assets/image/jobs/inventory-profit.png'),
					text: '盘盈管理',
					link: "/inventory/profitManage",
					permission: "pc_inventory_profitManage"
				}, {
					icon: require('@/assets/image/jobs/user.png'),
					text: '用户管理',
					link: "/system/user",
					permission: "pc_system_user"
				}, {
					icon: require('@/assets/image/jobs/log.png'),
					text: '日志管理',
					link: "/system/log",
					permission: "pc_system_log"
				}]
			}
		},
		created() {
			this.getCurrentTaskFun()
			this.getDataAssets()
			this.getDataInventory()
			// this.getFiscalAnalysisData()
		},
		methods: {
			async getDataAssets() {
				try {
					let ret = await getMyAssets();
					if (ret.code === 200) {
						this.assetsData = ret.data;
					}
				} catch(e) {
					
				}
			},
			async getCurrentTaskFun() {
				try {
					let ret = await getCurrentTask({});
					if (ret.code === 200) {
						this.currTaskId = ret.data.id;
						this.currTaskInfo = ret.data;
						if (this.$store.state.system.permissionCodes.includes('pc_jobs_transfer')) {
							this.getUnitTaskSearchListFun(ret.data.id)
						}
					}
				} catch(e) {
					
				}
			},
			async getDataInventory() {
				try {
					let ret = await getMyInventory({});
					if (ret.code === 200) {
						this.inventoryData = ret.data
						this.inventoryHead = ret.data.head
						// this.inventoryList = ret.data.list
						this.unitRank = ret.data.chart.slice(0,10);
					}
				} catch(e) {
				}
			},
			async getUnitTaskSearchListFun(id) {
				try {
					let ret = await getUnitTaskSearchList({
						taskId: id
					});
					if (ret.code === 200) {
						this.inventoryList = ret.data.list;
					}
				} catch(e) {
					
				}
			},
			// async getFiscalAnalysisData() { // 我的盘点图表数据
			// 	try {
			// 		let ret = await getFiscalAnalysis({});
			// 		if (ret.code === 200) {
			// 			console.log("analysisData",ret)
			// 			// this.analysisData = ret.data;
			// 			// this.unitData.mangerUnitNum = ret.data.mangerUnitNum
			// 			// this.unitData.outOfDateNum = ret.data.outOfDateNum
			// 			this.unitRank = ret.data.unitTaskNumVoList
			// 			// console.log("unitData",this.unitData)
			// 			console.log("unitRank",this.unitRank)
			// 		}
			// 	} catch(e) {
					
			// 	}
			// },
			onTodoList() {
				this.$router.push({
					path: "/jobs/todoList"
				})
			},
			onMyProperty() {
				if (this.$store.state.system.permissionCodes.includes('pc_assets_myself')) {
					this.$router.push({
						path: "/property/myself"
					})
				} else {
					this.$router.push({
						path: "/property/propertManage"
					})
				}
			},
			onNoticeClick() {
				this.$router.push({
					path: "/system/notice"
				})
			},
			onInventoryTrans() {
				this.$router.push({
					path: "/inventory/taskProgressManage",
					query: {
						id: this.currTaskInfo.id,
						taskName: this.currTaskInfo.name
					}
				})
			},
			onGotoPage(link) {
				this.$router.push({
					path: link
				})
			}
		}
	}
</script>

<style scoped>
	.ui-margin__top40 {
		margin-top: 40px;
	}
	.ui-relative {
		position: relative;
	}
	.ui-line {
		height: 100%;
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
	}
	.ui-property {
		display: flex;
		margin-top: 20px;
		/* flex-wrap: wrap; */
		justify-content: space-between;
	}
	.ui-property__count {
		width: 48%;
		height: 112px;
		padding: 20px 15px;
		background: #4982F2;
		border-radius: 5px;
		background-repeat: no-repeat;
		background-position: bottom right;
		background-size: contain;
	}
	.ui-property__text {
		font-size: 14px;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 19px;
	}
	.ui-property__allNum {
		margin-top: 10px;
		font-size: 16px;
		font-weight: 500;
		color: #d1dffc;
		vertical-align: text-bottom;
	}
	.ui-property__allNum span {
		padding-right: 2px;
		font-size: 20px;
		font-weight: bold;
		color: #FFFFFF;
		line-height: 42px;
	}
	.ui-property__item {
		width: 48%;
		height: 64px;
		padding: 8px 0;
		text-align: center;
		border-radius: 5px;
		border: 1px solid #E7E7E7;
		cursor: pointer;
	}
	.ui-property__name {
		font-size: 14px;
		font-weight: 400;
		color: #303A5D;
		line-height: 16px;
	}
	.ui-property__num {
		margin-top: 4px;
		font-size: 24px;
		font-weight: bold;
		color: #4F7EF5;
		line-height: 28px;
	}
</style>